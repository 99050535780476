@import "../../../../assets/styles/static/mixins";

.button {
	height: 40px;
	width: 100%;
	padding: 0 12px;
	color: #FFFFFF;
	background-color: var(--blue);
	border-radius: var(--border-radius);
	transition: var(--transition);
	text-align: center;
	&:hover {
		background-color: var(--blue-light);
	}
	&:disabled {
		opacity: .5;
		cursor: not-allowed;
		&:hover {
			background-color: var(--blue);
		}
	}
}

.outlined {
	background-color: transparent;
	color: var(--blue);
	padding: 0;
	width: fit-content;
	height: auto;
	&:hover {
		color: var(--blue-light);
		background-color: transparent;
	}
}

.red {
	background-color: var(--red);
	transition: var(--transition);
	&:hover {
		background-color: var(--red-light);
	}
}