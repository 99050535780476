@import '../../../assets/styles/static/mixins';

.image {
  width: 180px;
  height: 136px;
  position: relative;
  cursor: pointer;

  &__wrapper {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  img {
    width: 100%;
    height: inherit;
    border-radius: var(--border-radius);
  }

  .delete {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: var(--border-radius);
    @include flex();
  }

  &:hover {
    .delete {
      opacity: 1;
    }
    img {
      opacity: 0.3;
    }
  }
}
