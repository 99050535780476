@import '../../../../assets/styles/static/mixins';

.body {
  margin-bottom: 30px;
}

.body__title {
  margin-bottom: 15px;
}

.blocks {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-bottom: 15px;
}

.block {
  width: 180px;
  cursor: pointer;
}

.wrapper {
  max-width: 180px;
  width: 100%;
  height: 136px;

  img {
    border-radius: var(--border-radius);
    border: var(--border);
    height: inherit;
    width: 100%;
  }
}

.name__wrapper {
  position: relative;
}

.name {
  text-transform: uppercase;
  margin: 15px 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  &:hover + div {
   opacity: 1;
   pointer-events: all;
   visibility: visible
   ;
  }
}

.fullName {
  position: absolute;
  width: 200px;
  padding: 5px;
  bottom: 40px;
  left: 0;
  z-index: 1010;
  background-color: var(--white-obj);
  border-radius: var(--border-radius);
  border: var(--border);
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.price span {
  font-family: var(--R_Medium), sans-serif;
  margin-right: 10px;
}

.selectedBg {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: var(--transition);
  svg {
    width: 40px;
    height: 29px;
  }
}

.block.edit {
  width: 150px;
  @include flex(space-between, flex-start);
  flex-direction: column;

  .wrapper {
    width: 150px;
    height: 112px;
    position: relative;
  }

  .name {
    text-transform: uppercase;
    margin: 13px 0 15px;
  }

  .price span {
    margin-right: 15px;
  }

  .selectedBg {
    @include flex();
  }

  &.selected {
    .name {
      color: var(--blue);
    }
    .wrapper img {
      opacity: 0.5;
    }
    .selectedBg {
      opacity: 1;
      overflow: visible;
      pointer-events: all;
    }
  }
}
