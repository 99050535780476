@import '../../../../assets/styles/static/mixins';

.block {
  @include flex(flex-start);
  position: relative;
  max-width: 745px;
  width: 100%;
  background-color: var(--white-obj);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 15px;

  .photo__wrapper {
    cursor: pointer;
    max-width: 180px;
    width: 100%;
    height: 208px;
  }

  img {
    border-radius: var(--border-radius);
    border: var(--border);
    height: inherit;
  }

  .info {
    padding: 20px;
    width: 100%;
  }
}

.list {
  margin-bottom: 41px;
  &__item .header {
    font-family: var(--R_Medium), sans-serif;
    color: var(--gray-dark);
  }
  &__item:not(:last-child) {
    margin-bottom: 15px;
  }
}

.functions {
  @include flex(flex-start);
  .function:not(:last-child) {
    margin-right: 20px;
  }
}

.changes {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  line-height: 14px;
  color: #ff6b6b;
  cursor: pointer;
  p,
  svg {
    vertical-align: middle;
  }
  p {
    font-family: var(--R_Medium), sans-serif;
    display: inline-block;
    margin-right: 10px;
  }
  &__list {
    position: absolute;
    top: 25px;
    left: 100px;
    z-index: 5;
    width: 300px;
    background-color: var(--white-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    padding: 15px;
    font-family: var(--R_Regular), sans-serif;
    font-size: 14px;
    line-height: 16px;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    &.active {
      opacity: 1;
      pointer-events: all;
      overflow: visible;
    }
  }
  &__item:first-child {
    color: var(--gray-dark);
  }
  &__item:not(:first-child) {
    margin-top: 15px;
    color: var(--blue);
    text-decoration: underline;
  }
  &__btn {
    margin-top: 15px;
    color: var(--red);
    &:hover {
      color: var(--red-light);
    }
  }
}

.photo__count {
  position: absolute;
  left: 15px;
  bottom: 15px;
  color: var(--blue);
}
