.additionalField {
  position: relative;
}

.versionBtn {
  position: absolute;
  top: 21px;
  right: 90px;
  transform: translateY(-50%);
}

.new {
	right: 125px;
	color: rgb(58, 154, 58);
	&:hover {
		color: rgb(58, 154, 58);
	}
	&.active {
		border-bottom: 1px solid rgb(58, 154, 58);;
	}
}

.old {
	color: var(--red);
	&:hover {
		color: var(--red);
	}
	&.active {
		border-bottom: 1px solid var(--red);
	}
}