#projects {
  position: relative;

  .projects__title {
    margin-top: 23px;
    margin-bottom: 30px;
  }

  .projects__input {
    margin-bottom: 10px;
    input {
      background-color: var(--white-obj);
      height: 41.6px;
      padding: 10px 12px 11px;
    }
    input,
    input::placeholder {
      color: var(--gray-dark);
    }
  }
}
