@import '../../../../assets/styles/static/mixins';

.modal {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	background-color: rgba(#000, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.content {
	max-width: 870px;
	width: 100%;
	padding: 30px 22px 15px 27px;
	position: absolute;
	background-color: var(--white-obj);
	&.dark {
		background-color: #2C2C2C;
	}
}

.header {
	@include flex(space-between);
	margin-bottom: 10px;
}

.close {
	cursor: pointer;
	position: absolute;
	top: 21px;
	right: 37px;
	&__icon path{
		transition: var(--transition);
		fill: #EBEBEB;
	}
	&:hover &__icon path{
		fill: rgba(#000, 0.5);
	}
}

.body {
	max-height: 75vh;
	width: 100%;
	overflow-y: scroll;
	padding: 10px 45px 0 3px;
	&::-webkit-scrollbar {
    width: 6px;
  }
  // &::-webkit-scrollbar-track {
	// 	background: rgba(#D9D9D9, .3); 
	// }
  &::-webkit-scrollbar-thumb {
    background: rgba(#D9D9D9, .5);
    border-radius: 3px;
  }
}