@import '../../../assets/styles/static/mixins';

.tabs__titles {
  @include flex();
  border: var(--border);
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  width: 100%;
	position: relative;
  .tabs__title {
    padding: 10px 15px;
    cursor: pointer;
    width: 50%;
		color: var(--blue);
    background-color: transparent;
		transition: var(--transition);
    &.active {
			color: #fff;
      background-color: var(--blue);
    }
  }
}
