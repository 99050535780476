.checkbox {
  width: fit-content;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
  }

  input:checked + label .checkbox__label {
    .checkbox__icon {
      opacity: 1;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--R_Light), sans-serif;
    color: var(--gray-dark);
    .checkbox__label {
      width: 19px;
      height: 19px;
      margin-right: 15px;
      border: 1px solid #d7d7d7;
      background-color: #FFFFFF;
      border-radius: var(--border-radius);

      .checkbox__icon {
        transition: var(--transition);
        opacity: 0;
      }
    }
    .red {
      border: 1px solid var(--red);
      .checkbox__icon {
        transform: translateX(1px);
      }
      .checkbox__icon path{
        fill: var(--red);
      }
    }
    &.whithoutChangeTheme {
      color: #4C4C4C;
    }
  }
}
